import React from 'react'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Slider.css'

import image1 from '../assest/img/banner2.jpg'
import image2 from '../assest/img/banner1.jpg'
import image3 from '../assest/img/banner3.jpg'


function Slider() {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image1}
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Healing body, mind, and spirit!</h3>
                    <p>Show the world a healthy face!</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image2}
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h3>Creating your beauty at New Level</h3>
                    <p>Feel natural, be natural.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image3}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>See yourself in a New Light.</h3>
                    <p>Show the world a healthy face!</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            {/* <div className="scrolldwn-btn">
                <a href="#about" className="btn-scroll">
                    <div className="scroll-arrow"></div>
                    <div className="scroll-arrow"></div>
                    <div className="scroll-arrow"></div>
                </a>
            </div> */}
        </>
    )
}

export default Slider
