import React from 'react'
import './Admission.css'
import Footer from '../Footer'



import { Container, Row, Col } from 'react-bootstrap'

import admission_banner from '../../assest/img/banner8.jpg'

function Admission() {
    return (
        <div className="admission">
            <div className="admission-header">
                <img src={admission_banner} alt="" />
            </div>

            <Container className="py-5">
                <Row>
                    <Col md={12} className="py-5 text-center">
                        <h6 className="sub-title">Admission</h6>
                        <h3 className="hny-title">Get The Skills Now</h3>
                        
                    </Col>
                    <Col md={12}>
                        <p>All new entrants are expected to fill an application form which is available online. You can also contact the school during office hours to pick a form. No entrance  examination is required. However, the applicant must be able to read and write English.</p>
                        <p>The school has made provision for working students and those who want to polish their knowledge and skills in the cosmetology and beauty fields. 
                        </p>
                    </Col>
                </Row>
            </Container>

            

            <Footer />
        </div>
    )
}

export default Admission
