import React, { useState } from "react";
import "./ImgView.css";
import { gallery } from "./data/galleryData";

export const ImgView = () => {
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} alt="" />
        <span onClick={() => setModel(false)}>
          <i className="fa fa-times"></i>
        </span>
      </div>
      <div className="imgview">
        {gallery.map((item, index) => {
          return (
            <div className="images" key={index} onClick={() => getImg(item.imgSrc)}>
              <img src={item.imgSrc} alt="" style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
  );
};
