import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Header.css'
import logo from '../assest/img/logo.png'

function Header() {
    const [click, setClick] = useState(false)
    const [header, setHeader] = useState(false)


    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)


    const changeHeaderBackground = () => {
        if(window.scrollY >= 65.1){
            setHeader(true)
        }else{
            setHeader(false)
        }
    }

    window.addEventListener('scroll', changeHeaderBackground)

    return (
        <div className={header ? 'header sticky' : 'header'}>
            <div className="header__logo">
                {/* <h1>GoshenEm</h1> */}
                <Link to="/"> <img src={logo} alt="" className="logo" /></Link>
            </div>
            <div className="header__nav">
                <ul className={click ? 'menu active' : 'menu'}>
                    <li className="nav-menu">
                        <Link to="/" className="nav" onClick={closeMobileMenu}>home</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/about" className="nav" onClick={closeMobileMenu}>about</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/service" className="nav" onClick={closeMobileMenu}>services</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/gallery" className="nav" onClick={closeMobileMenu}>gallery</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/course" className="nav" onClick={closeMobileMenu}>courses</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/admission" className="nav" onClick={closeMobileMenu}>admission</Link>
                    </li>
                    <li className="nav-menu">
                        <Link to="/contact" className="nav" onClick={closeMobileMenu}>contact</Link>
                    </li>
                </ul>

                <div className="menu-icon" onClick={handleClick}>
                    <div className={click ? 'closed' : 'hamburger1'}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
