import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Testimonial.css";

function Testimonial() {
  let settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <Slider {...settings}>
        <div className="testimonial-wrapper">
          <div className="testimonial-card">
            <div className="testimonial-content">
              <div className="testimonial-content-info py-3 px-4">
                <p>
                  <span>
                    <i className="fa fa-quote-left"></i>
                  </span>
                  I met Patricia Emefa Ametame the Chief Executive Officer of GoshenEm Institute and Spa in 2003 at a fair held at Trade Fair Center La, Accra Ghana ( at Dark and
                  Lovely stand). Since then, I have not looked back when it comes to my hair and skin needs.
                </p>

                <p>
                  Emefa as she is popularly known has become a friend, a sister, a mentor, my beautician (all in one). GoshenEm services are excellent and exceptional. They don’t
                  only take care of your beauty needs but also take interest in the well-being of their clients. Always improving their knowledge with the current happenings in the
                  industry so as to give superior quality services to their clients. Birthdays are always wonderful with special surprise packages free of charge for their clients.
                </p>

                <p>
                  GoshenEm Institute and Spa gave me a beautiful bridal look on my wedding day in 2008. Wherever I am, wherever I go, I always come back to patronize GoshenEm
                  Institute and Spa.{" "}
                </p>
                <h3>- Elfreda</h3>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="testimonial-wrapper">
                    <div className="testimonial-card">
                        <div className="testimonial-content">
                            <div className="testimonial-content-info py-3 px-4">
                                <p><span><i className="fa fa-quote-left"></i></span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id accusantium
										officia quod quasi necessitatibus perspiciatis Harum error provident 
                                        quibusdam tenetur.</p>
                                <h3>- Facilas</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimonial-wrapper">
                    <div className="testimonial-card">
                        <div className="testimonial-content">
                            <div className="testimonial-content-info py-3 px-4">
                                <p><span><i className="fa fa-quote-left"></i></span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id accusantium
										officia quod quasi necessitatibus perspiciatis Harum error provident 
                                        quibusdam tenetur.</p>
                                <h3>- Facilas</h3>
                            </div>
                        </div>
                    </div>
                </div> */}
      </Slider>
    </div>
  );
}

export default Testimonial;
