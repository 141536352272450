import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import About from "./components/pages/About";
import Admission from "./components/pages/Admission";
import Contact from "./components/pages/Contact";
import Course from "./components/pages/Course";
import Gallery from "./components/pages/Gallery";
import Home from "./components/pages/Home";
import Service from "./components/pages/Service";
import NotFound from "./components/pages/NotFound";
import "./App.css";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/about" exact component={About} />
        <Route path="/service" exact component={Service} />
        <Route path="/gallery" exact component={Gallery} />
        <Route path="/course" exact component={Course} />
        <Route path="/admission" exact component={Admission} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
