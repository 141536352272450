import React from "react";

function NotFound() {
  return (
    <div className="notfound">
      <h1>Oops!</h1>
      <h3>404 - page not found</h3>
      <p>The page you are looking for might have been removed or had its name changed or temporarily unavailable.</p>
      <a href="/">go to homepage</a>
    </div>
  );
}

export default NotFound;
