import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <>
      <div className="footer py-5 px-4">
        <Container>
          <Row>
            <Col md={4}>
              <div className="footer-abt">
                <h2>about us</h2>
                <p>
                  GoshenEm Institute and Spa (formally EMs Beauty Salon) was established in 1998. EMs Beauty Salon was basically providing salon (spa) services to clients within
                  Accra and its environment. In 2018, EMs Beauty Salon was upgraded and elevated to a beauty school, GoshenEm Institute and Spa.
                </p>
                <a href="https://www.facebook.com/goshenemgh/" target="_blank" rel="noreferrer" className="social">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/goshenemgh" target="_blank" rel="noreferrer" className="social">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://wa.me/233244863408?text=I'm%20interested%20in%20your%20course" className="social">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col xs={6} md={6}>
                  <div className="footer-therapy">
                    <h4>Therapies</h4>
                    <div className="therapy">
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> aromatherapy
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> waxing
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> reflexology
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> manicure and pedicure
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> massage
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> reflexology
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> facils
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div className="footer-category">
                    <h4>Categories</h4>
                    <div className="category">
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> hot stone massage (not stone)
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> waxing
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> stretch massage
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> body treatments
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> swedish massage
                      </Link>
                      <Link to="">
                        <i className="fa fa-angle-double-right"></i> facial treatments
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div className="footer-instagram">
                <h4>instagram</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="foot-copyright">
        <p>
          Copyright {new Date().getFullYear()} @ GoshenEm - All Right Reserved || Developed by <a href="https://troytechgh.com">Troy Tech GH</a>
        </p>
      </div>
    </>
  );
}

export default Footer;
