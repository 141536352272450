import img1 from "../../assest/gallery/goshen1.jpg";
import img2 from "../../assest/gallery/goshen2.jpg";
import img3 from "../../assest/gallery/goshen3.jpg";
import img4 from "../../assest/gallery/goshen4.jpg";
import img5 from "../../assest/gallery/goshen5.jpg";
import img6 from "../../assest/gallery/goshen6.jpg";
import img7 from "../../assest/gallery/goshen7.jpg";
import img8 from "../../assest/gallery/goshen8.jpg";
import img9 from "../../assest/gallery/goshen9.jpg";
import img10 from "../../assest/gallery/goshen10.jpg";
import img11 from "../../assest/gallery/goshen11.jpg";
import img12 from "../../assest/gallery/goshen12.jpg";
import img13 from "../../assest/gallery/goshen13.jpg";
import img14 from "../../assest/gallery/goshen14.jpg";
import img15 from "../../assest/gallery/goshen15.jpg";
import img16 from "../../assest/gallery/goshen16.jpg";
import img17 from "../../assest/gallery/goshen17.jpg";
import img18 from "../../assest/gallery/goshen18.jpg";
import img19 from "../../assest/gallery/goshen19.jpg";
import img20 from "../../assest/gallery/goshen20.jpg";
import img21 from "../../assest/gallery/goshen21.jpg";
import img22 from "../../assest/gallery/goshen22.jpg";
import img23 from "../../assest/gallery/goshen23.jpg";
import img24 from "../../assest/gallery/goshen24.jpg";
import img25 from "../../assest/gallery/goshen25.jpg";
import img26 from "../../assest/gallery/goshen26.jpg";
import img27 from "../../assest/gallery/goshen27.jpg";
import img28 from "../../assest/gallery/goshen28.jpg";
import img29 from "../../assest/gallery/goshen29.jpg";
import img30 from "../../assest/gallery/goshen30.jpg";
import img31 from "../../assest/gallery/goshen31.jpg";
import img32 from "../../assest/gallery/goshen32.jpg";
import img33 from "../../assest/gallery/goshen33.jpg";
import img34 from "../../assest/gallery/goshen34.jpg";
import img35 from "../../assest/gallery/goshen35.jpg";
import img36 from "../../assest/gallery/goshen36.jpg";
import img37 from "../../assest/gallery/goshen37.jpg";
import img38 from "../../assest/gallery/goshen38.jpg";
import img39 from "../../assest/gallery/goshen39.jpg";
import img40 from "../../assest/gallery/goshen40.jpg";
import img41 from "../../assest/gallery/goshen41.jpg";
import img42 from "../../assest/gallery/goshen42.jpg";
import img43 from "../../assest/gallery/goshen43.jpg";
import img44 from "../../assest/gallery/goshen44.jpg";
import img45 from "../../assest/gallery/goshen45.jpg";
import img46 from "../../assest/gallery/goshen46.jpg";
import img47 from "../../assest/gallery/goshen47.jpg";
import img48 from "../../assest/gallery/goshen48.jpg";
import img49 from "../../assest/gallery/goshen49.jpg";
import img50 from "../../assest/gallery/goshen50.JPG";
import img51 from "../../assest/gallery/goshen51.JPG";
import img52 from "../../assest/gallery/goshen52.JPG";
import img53 from "../../assest/gallery/goshen53.JPG";
import img54 from "../../assest/gallery/goshen54.JPG";
import img55 from "../../assest/gallery/goshen55.JPG";
import img56 from "../../assest/gallery/goshen56.JPG";
import img57 from "../../assest/gallery/goshen57.JPG";
import img58 from "../../assest/gallery/goshen58.JPG";
import img59 from "../../assest/gallery/goshen59.jpg";
import img60 from "../../assest/gallery/goshen60.jpg";
import img61 from "../../assest/gallery/goshen61.jpg";
import img62 from "../../assest/gallery/goshen62.jpg";
import img63 from "../../assest/gallery/goshen63.jpg";
import img64 from "../../assest/gallery/goshen64.jpg";
import img65 from "../../assest/gallery/goshen65.jpg";
import img66 from "../../assest/gallery/goshen66.jpg";
import img67 from "../../assest/gallery/goshen67.jpg";
import img68 from "../../assest/gallery/goshen68.jpg";
import img69 from "../../assest/gallery/goshen69.jpg";
import img70 from "../../assest/gallery/goshen70.jpg";
import img71 from "../../assest/gallery/goshen71.jpg";
import img72 from "../../assest/gallery/goshen72.jpg";
import img73 from "../../assest/gallery/goshen73.jpg";
import img74 from "../../assest/gallery/goshen74.jpg";
import img75 from "../../assest/gallery/goshen75.jpg";
import img76 from "../../assest/gallery/goshen76.jpg";
import img77 from "../../assest/gallery/goshen77.jpg";
import img78 from "../../assest/gallery/goshen78.jpg";
import img79 from "../../assest/gallery/goshen79.jpg";
import img80 from "../../assest/gallery/goshen80.jpg";
import img81 from "../../assest/gallery/goshen81.jpg";
import img82 from "../../assest/gallery/goshen82.jpg";
import img83 from "../../assest/gallery/goshen83.jpg";
import img84 from "../../assest/gallery/goshen84.jpg";
import img85 from "../../assest/gallery/goshen85.jpg";
import img86 from "../../assest/gallery/goshen86.jpg";
import img87 from "../../assest/gallery/goshen87.jpg";
import img88 from "../../assest/gallery/goshen88.jpg";
import img89 from "../../assest/gallery/goshen89.jpg";
import img90 from "../../assest/gallery/goshen90.jpg";
import img91 from "../../assest/gallery/goshen91.jpg";
import img92 from "../../assest/gallery/goshen92.jpg";
import img93 from "../../assest/gallery/goshen93.jpg";
import img94 from "../../assest/gallery/goshen94.jpg";
import img95 from "../../assest/gallery/goshen95.jpg";
import img96 from "../../assest/gallery/goshen96.jpg";
import img97 from "../../assest/gallery/goshen97.jpg";
import img98 from "../../assest/gallery/goshen98.jpg";
import img99 from "../../assest/gallery/goshen99.jpg";
import img100 from "../../assest/gallery/goshen100.jpg";
import img101 from "../../assest/gallery/goshen101.jpg";
import img102 from "../../assest/gallery/goshen102.jpg";
import img103 from "../../assest/gallery/goshen103.jpg";
import img104 from "../../assest/gallery/goshen104.jpg";
import img105 from "../../assest/gallery/goshen105.jpg";
import img106 from "../../assest/gallery/goshen106.jpg";
import img107 from "../../assest/gallery/goshen107.jpg";
import img108 from "../../assest/gallery/goshen108.jpg";
import img109 from "../../assest/gallery/goshen109.jpg";
import img110 from "../../assest/gallery/goshen110.jpg";

export const gallery = [
  {
    id: 1,
    imgSrc: img1,
  },
  {
    id: 2,
    imgSrc: img2,
  },
  {
    id: 3,
    imgSrc: img3,
  },
  {
    id: 4,
    imgSrc: img4,
  },
  {
    id: 5,
    imgSrc: img5,
  },
  {
    id: 6,
    imgSrc: img6,
  },
  {
    id: 7,
    imgSrc: img7,
  },
  {
    id: 8,
    imgSrc: img8,
  },
  {
    id: 9,
    imgSrc: img9,
  },
  {
    id: 10,
    imgSrc: img10,
  },
  {
    id: 11,
    imgSrc: img11,
  },
  {
    id: 12,
    imgSrc: img12,
  },
  {
    id: 13,
    imgSrc: img13,
  },
  {
    id: 14,
    imgSrc: img14,
  },
  {
    id: 15,
    imgSrc: img15,
  },
  {
    id: 16,
    imgSrc: img16,
  },
  {
    id: 17,
    imgSrc: img17,
  },
  {
    id: 18,
    imgSrc: img18,
  },
  {
    id: 19,
    imgSrc: img19,
  },
  {
    id: 20,
    imgSrc: img20,
  },
  {
    id: 21,
    imgSrc: img21,
  },
  {
    id: 22,
    imgSrc: img22,
  },
  {
    id: 23,
    imgSrc: img23,
  },
  {
    id: 24,
    imgSrc: img24,
  },
  {
    id: 25,
    imgSrc: img25,
  },
  {
    id: 26,
    imgSrc: img26,
  },
  {
    id: 27,
    imgSrc: img27,
  },
  {
    id: 28,
    imgSrc: img28,
  },
  {
    id: 29,
    imgSrc: img29,
  },
  {
    id: 30,
    imgSrc: img30,
  },
  {
    id: 31,
    imgSrc: img31,
  },
  {
    id: 32,
    imgSrc: img32,
  },
  {
    id: 33,
    imgSrc: img33,
  },
  {
    id: 34,
    imgSrc: img34,
  },
  {
    id: 35,
    imgSrc: img35,
  },
  {
    id: 36,
    imgSrc: img36,
  },
  {
    id: 37,
    imgSrc: img37,
  },
  {
    id: 38,
    imgSrc: img38,
  },
  {
    id: 39,
    imgSrc: img39,
  },
  {
    id: 40,
    imgSrc: img40,
  },
  {
    id: 41,
    imgSrc: img41,
  },
  {
    id: 42,
    imgSrc: img42,
  },
  {
    id: 43,
    imgSrc: img43,
  },
  {
    id: 44,
    imgSrc: img44,
  },
  {
    id: 45,
    imgSrc: img45,
  },
  {
    id: 46,
    imgSrc: img46,
  },
  {
    id: 47,
    imgSrc: img47,
  },
  {
    id: 48,
    imgSrc: img48,
  },
  {
    id: 49,
    imgSrc: img49,
  },
  {
    id: 50,
    imgSrc: img50,
  },
  {
    id: 51,
    imgSrc: img51,
  },
  {
    id: 52,
    imgSrc: img52,
  },
  {
    id: 53,
    imgSrc: img53,
  },
  {
    id: 54,
    imgSrc: img54,
  },
  {
    id: 55,
    imgSrc: img55,
  },
  {
    id: 56,
    imgSrc: img56,
  },
  {
    id: 57,
    imgSrc: img57,
  },
  {
    id: 58,
    imgSrc: img58,
  },
  {
    id: 59,
    imgSrc: img59,
  },
  {
    id: 60,
    imgSrc: img60,
  },
  {
    id: 61,
    imgSrc: img61,
  },
  {
    id: 62,
    imgSrc: img62,
  },
  {
    id: 63,
    imgSrc: img63,
  },
  {
    id: 64,
    imgSrc: img64,
  },
  {
    id: 65,
    imgSrc: img65,
  },
  {
    id: 66,
    imgSrc: img66,
  },
  {
    id: 67,
    imgSrc: img67,
  },
  {
    id: 68,
    imgSrc: img68,
  },
  {
    id: 69,
    imgSrc: img69,
  },
  {
    id: 70,
    imgSrc: img70,
  },
  {
    id: 71,
    imgSrc: img71,
  },
  {
    id: 72,
    imgSrc: img72,
  },
  {
    id: 73,
    imgSrc: img73,
  },
  {
    id: 74,
    imgSrc: img74,
  },
  {
    id: 75,
    imgSrc: img75,
  },
  {
    id: 76,
    imgSrc: img76,
  },
  {
    id: 77,
    imgSrc: img77,
  },
  {
    id: 78,
    imgSrc: img78,
  },
  {
    id: 79,
    imgSrc: img79,
  },
  {
    id: 80,
    imgSrc: img80,
  },
  {
    id: 81,
    imgSrc: img81,
  },
  {
    id: 82,
    imgSrc: img82,
  },
  {
    id: 83,
    imgSrc: img83,
  },
  {
    id: 84,
    imgSrc: img84,
  },
  {
    id: 85,
    imgSrc: img85,
  },
  {
    id: 86,
    imgSrc: img86,
  },
  {
    id: 87,
    imgSrc: img87,
  },
  {
    id: 88,
    imgSrc: img88,
  },
  {
    id: 89,
    imgSrc: img89,
  },
  {
    id: 90,
    imgSrc: img90,
  },
  {
    id: 91,
    imgSrc: img91,
  },
  {
    id: 92,
    imgSrc: img92,
  },
  {
    id: 93,
    imgSrc: img93,
  },
  {
    id: 94,
    imgSrc: img94,
  },
  {
    id: 95,
    imgSrc: img95,
  },
  {
    id: 96,
    imgSrc: img96,
  },
  {
    id: 97,
    imgSrc: img97,
  },
  {
    id: 98,
    imgSrc: img98,
  },
  {
    id: 99,
    imgSrc: img99,
  },
  {
    id: 100,
    imgSrc: img100,
  },
  {
    id: 101,
    imgSrc: img101,
  },
  {
    id: 102,
    imgSrc: img102,
  },
  {
    id: 103,
    imgSrc: img103,
  },
  {
    id: 104,
    imgSrc: img104,
  },
  {
    id: 105,
    imgSrc: img105,
  },
  {
    id: 106,
    imgSrc: img106,
  },
  {
    id: 107,
    imgSrc: img107,
  },
  {
    id: 108,
    imgSrc: img108,
  },
  {
    id: 109,
    imgSrc: img109,
  },
  {
    id: 110,
    imgSrc: img110,
  },
];
