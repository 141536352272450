import React from "react";
import "./Contact.css";
import Footer from "../Footer";
import * as emailjs from "emailjs-com";
import * as Yup from "yup";
import { Container, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

import contact_banner from "../../assest/img/banner8.jpg";

const onSubmit = async (values, actions) => {
  emailjs.send("service_qfgquej", "template_vy2a1qv", values, "user_rrgx4DVK6QHADefMdv0SF").then((result) => {
    console.log(result.text);
  });

  await new Promise((resolve) => setTimeout(resolve, 2000));
  actions.resetForm();
};

function Contact() {
  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Please enter Full Name"),
      email: Yup.string().required("Please enter a valid email"),
      subject: Yup.string().required("Please a subject for your mail"),
      message: Yup.string().required("Please tell us how we can help you."),
    }),
    onSubmit,
    // onSubmit: (values) => {
    //     try {
    //         emailjs.send('service_qfgquej', 'template_vy2a1qv', values, 'user_rrgx4DVK6QHADefMdv0SF')
    //         .then((result) => {
    //             console.log(result.text)
    //         })
    //         console.log(onSubmit)
    //     } catch (error) {
    //     }

    //     console.log(values)
    // }
  });

  // function sendEmail(e){
  //     e.preventDefault()

  //         console.log(error.text)
  //     })
  //     e.target.reset()
  // }

  return (
    <div className="contact">
      <div className="contact-header">
        <img src={contact_banner} alt="" />
      </div>

      <Container className="py-5">
        <Row>
          <Col className="py-5 text-center">
            <h6 className="sub-title">Contact</h6>
            <h3 className="hny-title">Get In Touch With Us</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={4} className="mb-3">
                  <input
                    type="text"
                    className={errors.fullname && touched.fullname ? "form-control input-error" : "form-control"}
                    onChange={handleChange}
                    value={values.fullname}
                    placeholder="Full name"
                    name="fullname"
                  />
                  {errors.fullname && touched.fullname && <p className="error">{errors.fullname}</p>}
                </Col>
                <Col xs={12} md={4} className="mb-3">
                  <input
                    type="email"
                    className={errors.email && touched.email ? "form-control input-error" : "form-control"}
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Email"
                    name="email"
                  />
                  {errors.email && touched.email && <p className="error">{errors.email}</p>}
                </Col>
                <Col xs={12} md={4} className="mb-3">
                  <input
                    type="text"
                    className={errors.subject && touched.subject ? "form-control input-error" : "form-control"}
                    onChange={handleChange}
                    value={values.subject}
                    placeholder="Subject"
                    name="subject"
                  />
                  {errors.subject && touched.subject && <p className="error">{errors.subject}</p>}
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <textarea
                    type="text"
                    className={errors.message && touched.message ? "form-control input-error" : "form-control"}
                    onChange={handleChange}
                    value={values.message}
                    rows="8"
                    placeholder="Message"
                    name="message"></textarea>
                  {errors.message && touched.message && <p className="error">{errors.message}</p>}
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <button type="submit" className="btn btn-goshen" disabled={isSubmitting}>
                    Submit Now <i className="fa fa-paper-plane"></i>
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
          <Col>
            <section className="contact-info text-center">
              <Row>
                <Col>
                  <div className="info-location mb-3">
                    <i className="fa fa-map-marker"></i>
                    <p> GA-202-1498, Nii Ayitey Street</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="info-address mb-3">
                    <i className="fa fa-address-book"></i>
                    P. O. Box 354, Art Center Accra
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="info-email mb-3">
                    <i className="fa fa-envelope fa-2x" style={{ marginRight: "10px", marginTop: "15px" }}></i>
                    <span> info@goshenemgh.com </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="info-phone mb-3">
                    <i className="fa fa-phone"></i>
                    <p>0302 4444 309, 0244 864308, 0502 407939, 0243 720393, 0204372039 </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="info-phone mb-3">
                    <i className="fab fa-whatsapp"></i>
                    <p>0244 863 408</p>
                  </div>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Container>

      {/* Contact info */}
      {/* <section className="contact-info text-center">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="info-location mb-3">
                <i className="fa fa-map-marker"></i>
                <p> GA-202-1498, Nii Ayitey Street</p>
              </div>
              <div className="info-address mb-3">
                <i className="fa fa-address-book"></i>
                P. O. Box 354, Art Center Accra
              </div>

              <div className="info-email mb-3">
                <i className="fa fa-envelope fa-2x" style={{ marginRight: "10px", marginTop: "15px" }}></i>
                <span> info@goshenemgh.com </span>
              </div>

              <div className="info-phone mb-3">
                <i className="fa fa-phone"></i>
                <p>0302 4444 309, 0244 864308, 0502 407939, 0243 720393, 0204372039 </p>
              </div>

              <div className="info-phone mb-3">
                <i className="fab fa-whatsapp"></i>
                <p>0244 863 408</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* Google Location */}
      <section className="contact-location text-center pb-5">
        <h3 className="hny-title pt-5">Get In Touch With Us</h3>

        <iframe
          width="100%"
          height="753"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Goshen%20Em%20school%20of%20Cosmetology,%20Achimota&t=&z=17&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title="MyLocation"></iframe>
      </section>

      <Footer />
    </div>
  );
}

export default Contact;
