import React from 'react'
import Footer from '../Footer'
import './Gallery.css'



import { Container, Row, Col } from 'react-bootstrap'

import gallery_banner from '../../assest/img/banner8.jpg'
import { ImgView } from '../ImgView'

function Gallery() {
    return (
        <div className="gallery">
            <div className="gallery-header">
                <img src={gallery_banner} alt="" />
            </div>

            <Container className="py-5">
                <Row>
                    <Col className="py-5 text-center">
                        <h6 className="sub-title">Our Gallery</h6>
                        <h3 className="hny-title">Beautiful Pictures</h3>

                        <p>This page is under-construction</p>
                    </Col>
                </Row>
                <Row>
                    <ImgView />
                </Row>
            </Container>
            

            <Footer />
        </div>
    )
}

export default Gallery
