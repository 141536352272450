import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

import './Gslider.css'


import slider1 from '../assest/img/pedicure.jpg'
import slider2 from '../assest/img/ab3.jpg'
import slider3 from '../assest/img/hair-spa.jpg'
import slider4 from '../assest/img/body-spa.jpg'
import slider5 from '../assest/img/hydro.jpg'
import slider6 from '../assest/img/ab7.jpg'



function Gslider() {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

    return (
        <>
        <div className="container-fuild">
        <Slider {...settings}>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider1} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Pedicure</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider2} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Facial</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider3} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Hair Spa</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider4} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Body Spa</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider5} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Hydro Therapy</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider6} alt="" />
                        </div>
                        <div className="gslider-content">
                            <div className="gslider-content-info py-3 px-4">
                                <h3>Nails</h3>
                                <Link to='' className="btn btn-goshen"> View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
        </Slider>
      </div>
        {/* <div className="container">
            <Slider {...settings}>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider1} alt="" />
                        </div>
                        <div className="gslider-content">
                            <h3>adfsd</h3>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider1} alt="" />
                        </div>
                        <div className="gslider-content">
                            <h3>adfsd</h3>
                        </div>
                    </div>
                </div>
                <div className="gslider-wrapper">
                    <div className="gslider-card">
                        <div className="gslider-card-image">
                            <img src={slider1} alt="" />
                        </div>
                        <div className="gslider-content">
                            <h3>adfsd</h3>
                        </div>
                    </div>
                </div>
            </Slider>
        </div> */}
        </>
    )
}

export default Gslider
