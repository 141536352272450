import React from "react";
import "./About.css";
import Footer from "../Footer";
// import Team from '../Team'

import { Container, Row, Col } from "react-bootstrap";

import about_banner from "../../assest/img/banner8.jpg";
import about from "../../assest/img/ceo.jpg";
import about_why from "../../assest/img/class.jpg";

function About() {
  return (
    <div className="about">
      <div className="about-header">
        <img src={about_banner} alt="" />
      </div>

      <Container className="">
        <Row>
          <Col className="py-3 text-center">
            <h6 className="sub-title">About</h6>
            <h3 className="hny-title">Get In Touch With Us</h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <div className="about-img">
              <img src={about} alt="" />
            </div>
          </Col>

          <Col md={8}>
            <div className="about-info">
              <p>
                GoshenEm Institute and Spa (formally EMs Beauty Salon) was established in 1998. EMs Beauty Salon was basically providing salon (spa) services to clients within
                Accra and its environment. In 2018, EMs Beauty Salon was upgraded and elevated to a beauty school, GoshenEm Institute and Spa. Currently the school offers students
                the educational advantages needed to ensure mutual success. We are approved by Government and our curriculum is designed not only to achieve the technical skills
                necessary in cosmetology &amp; beauty therapy but to make our students aware of the part they play in the service to others and to be sensitive and professional to
                their clients. This college catalogue is designed to help you see what our career-training college has to offer today, Visit our beautiful college and meet our
                educational team. Come and see why our students would receive the best education possible at the GoshenEm Institute and Spa. Each student admitted to the school
                receives individual instruction in technical skills, professional services, business and job-related information. Each student also participates in
                closely-supervised services while apprenticing in the student salon and spa. The class sizes are small to ensure maximum knowledge is impacted to individual
                students.
              </p>
            </div>
          </Col>
        </Row>

        <Row className=" py-5">
          <Col md={6}>
            <div className="about-mission">
              <h3 className="hny-title">Mission Statement</h3>
              <p>
                GoshenEm Institute and Spa is dedicated to educating quality students with the fundamental knowledge to meet the present and future demands of the cosmetology and
                beauty therapy industry
              </p>
            </div>
          </Col>

          <Col md={6}>
            <div className="about-vision">
              <h3 className="hny-title">Vision Statement</h3>
              <p>
                The vision of GoshenEm Institute and Spa is to foster the development of competent, responsible, motivated individuals in cosmetology &amp; beauty therapy related
                programs while in pursuit of a professional career.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <section>
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="hny-title">Education Objectives</h3>
              <p>
                Each student admitted to the college receives individual instruction in technical skills, professional services, business and job-related tuition. Every student
                participates in closely-supervised services while apprenticing in the student salon and spa. Each course offered by the college is designed to provide a broad base
                to maximize the employability of its graduates. Students are also encouraged to be self-employed.{" "}
              </p>
            </Col>

            <Col md={6}>
              <h3 className="hny-title">Slogan</h3>
              <p>GoshenEm........ Raising beauty professionals </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-why-us">
        <Container>
          <Row className="my-5 py-5">
            <Col md={6}>
              <div className="about-why-us-img">
                <img src={about_why} alt="" />
              </div>
            </Col>

            <Col md={6} className="pt-5">
              <h6 className="sub-title mt-3">why choose us</h6>
              <h3 className="hny-title">The Best For You</h3>
              <p>
                We are approved by Government and our curriculum is designed not only to achieve the technical skills necessary in cosmetology and beauty therapy but to make our
                students aware of the part they play in the service to others.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-team text-center">
                <h6 className="sub-title">our team</h6>
                <h3 className="hny-title">Meet Our Therapists</h3>
                <p className="text-center">
                  In this industry, we know quality educators make the difference. To go along with our proposed state-of-the-art facilities, our college provides seasoned,
                  experienced and professional educators to ensure our students succeed after graduation.
                </p>
              </div>
            </Col>
            {/* <Col>
                            <Row>
                                <Col className="my-2 mb-3 py-5">
                                    <Team />
                                </Col>
                            </Row>
                        </Col> */}
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default About;
