import React from "react";
import Slider from "../Slider";
import "./Home.css";
import { Link } from "react-router-dom";
import Gslider from "../Gslider";
import Testimonial from "../Testimonial";
import Footer from "../Footer";

import { Container, Row, Col, Card, CardGroup } from "react-bootstrap";

import background from "../../assest/img/img4.jpg";
import mission from "../../assest/img/mission.jpg";
import time from "../../assest/img/time.jpg";
import vision from "../../assest/img/vision.jpg";
import course1 from "../../assest/img/course1.jpg";
import course2 from "../../assest/img/course2.jpg";

function Home() {
  return (
    <div className="home">
      <Slider />
      {/* Home About */}
      <div className="home__about " id="about">
        <Container fluid>
          <Row>
            <Col className="py-4 my-5 text-center h-about">
              <h6 className="sub-title">About Us</h6>
              <h3 className="hny-title">Welcome to GoshenEm Institute and Spa</h3>
              <p className="mt-3">
                We are approved by Government and our curriculum is designed not only to achieve the technical skills necessary in cosmetology &amp; beauty therapy but to make our
                students aware of the part they play in the service to others...{" "}
              </p>
              <div className="ready-more mt-lg-5 mt-4">
                <Link className="btn btn-style btn-goshen" to="/about">
                  Read More <i className="fa fa-book-open"></i>{" "}
                </Link>
              </div>
            </Col>
          </Row>

          <Container className="">
            <Row>
              <CardGroup>
                <Card>
                  <Card.Img variant="top" src={time} />
                  {/* <i className="fa fa-clock text-center"></i> */}
                  <Card.Body className="white pb-5">
                    <Card.Title className="text-center" style={{ color: "#fff", marginBottom: "25px" }}>
                      Opening Hours
                    </Card.Title>
                    <Card.Text>
                      <div className="time white">
                        <h4>Monday - Friday </h4>
                        <span>8:00am - 5:00pm</span>
                      </div>
                      <div className="time">
                        <h4>Saturdays </h4>
                        <span>8:30am - 5:00pm</span>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" src={mission} />
                  {/* <i className="fa fa-cut text-center"></i> */}
                  <Card.Body className="text-center pb-5">
                    <Card.Title>Our Mission</Card.Title>
                    <Card.Text>GoshenEm Institute and Spa is dedicated to educating quality students with the fundamental knowledge to meet the present...</Card.Text>
                    <Link to="" className="btn btn-goshen">
                      Read More
                    </Link>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" src={vision} />
                  <Card.Body className="text-center pb-5">
                    <Card.Title>Our Vision</Card.Title>
                    <Card.Text>The vision of GoshenEm Institute and Spa is to foster the development of competent, responible, motivated individuals ...</Card.Text>
                    <Link to="" className="btn btn-goshen">
                      Read More
                    </Link>
                  </Card.Body>
                </Card>
              </CardGroup>
            </Row>
          </Container>
        </Container>
      </div>

      {/* Home Services*/}
      <section
        className="home-service py-5"
        style={{ background: `url(${background})`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <Container className="my-3">
          <Row>
            <Col md={6} className="py-5 h-service">
              <h6 className="sub-title">Health &amp; Relaxation</h6>
              <h3 className="hny-title">Our Services</h3>
              <p className="mt-3">
                We are approved by Government and our curriculum is designed not only to achieve the technical skills necessary in cosmetology &amp; beauty therapy but to make our
                students aware of the part they play in the service to others...{" "}
              </p>

              <div className="service-list">
                <div className="service-list-left">
                  <p>
                    <i className="fa fa-check"></i> facials
                  </p>
                  <p>
                    <i className="fa fa-check"></i> body treatment
                  </p>
                  <p>
                    <i className="fa fa-check"></i> waxing
                  </p>
                  <p>
                    <i className="fa fa-check"></i> massage
                  </p>
                  <p>
                    <i className="fa fa-check"></i> wave-on
                  </p>
                  <p>
                    <i className="fa fa-check"></i> nail extension
                  </p>
                  <p>
                    <i className="fa fa-check"></i> bridal make-up
                  </p>
                </div>
                <div className="service-list-right">
                  <p>
                    <i className="fa fa-check"></i> braiding
                  </p>
                  <p>
                    <i className="fa fa-check"></i> hands &amp; feet
                  </p>
                  <p>
                    <i className="fa fa-check"></i> aroma therapy
                  </p>
                  <p>
                    <i className="fa fa-check"></i> eye lashes
                  </p>
                  <p>
                    <i className="fa fa-check"></i> chemical hair relaxing
                  </p>
                  <p>
                    <i className="fa fa-check"></i> roller setting
                  </p>
                  <p>
                    <i className="fa fa-check"></i> styling
                  </p>
                  <p>
                    <i className="fa fa-check"></i> wigcaps
                  </p>
                </div>
              </div>
              <div className="ready-more mt-lg-5 mt-4">
                <Link className="btn btn-style btn-goshen" to="/about">
                  Detail Services
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Home course */}
      <section className="home-course">
        <div className="home-course-img">
          <img src={course1} alt="" />
        </div>
        <div className="home-course-detial">
          <h2>Diploma Course</h2>
          <p>
            Cosmetology is the study and application of hair, beauty, nail and skin treatments. Branches of specialty include hairstyling, skin care, cosmetics,
            manicures/pedicures, non-permanent hair removal such as waxing and sugaring, and permanent hair removal processes.{" "}
          </p>
          <Link to="" className="btn btn-goshen mt-5">
            Course
          </Link>
        </div>
      </section>

      <section className="home-course2">
        <div className="home-course-detial2">
          <h2>Advance Course</h2>
          <p>
            Beauty therapists are experts in facial and body care and have a strong knowledge of skin biology and cosmetic chemistry. ... Typical services include facials, hair
            removal, massage, make-up, lash and brow treatments, hair and scalp treatments and other treatments.
          </p>
          <Link to="" className="btn btn-goshen mt-5">
            Course
          </Link>
        </div>

        <div className="home-course-img2">
          <img src={course2} alt="" />
        </div>
      </section>

      {/* Home Gallery */}
      <section className="home-gallery">
        <Container fluid>
          <Row>
            <Col md={12} className="text-center">
              <h6 className="sub-title">View</h6>
              <h3 className="hny-title">Our Gallery</h3>
            </Col>
            <Col md={13} className="mt-3">
              <Gslider />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Home Testimonial */}
      <section className="home-testimonial">
        <Container fluid>
          <Row>
            <Col md={12} className="text-center">
              <h6 className="sub-title">Testimonial</h6>
              <h3 className="hny-title">What People Say About Us </h3>
            </Col>
            <Col md={{ span: 8, offset: 2 }} className="mt-3">
              <Testimonial />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
