import React from "react";
import "./Course.css";
import Footer from "../Footer";

import { Container, Row, Col, Card } from "react-bootstrap";

import course_banner from "../../assest/img/banner8.jpg";

function Course() {
  return (
    <div className="course">
      <div className="course-header">
        <img src={course_banner} alt="" />
      </div>

      <Container className="py-5">
        <Row>
          <Col className="py-5 text-center">
            <h6 className="sub-title">Our Course</h6>
            <h3 className="hny-title">What We Offer</h3>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Diploma Course</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  Cosmetology is the study and application of hair, beauty, nail and skin treatments. Branches of specialty include hairstyling, skin care, cosmetics,
                  manicures/pedicures, non-permanent hair removal such as waxing and sugaring, and permanent hair removal processes.
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Advanced Diploma Course</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  Beauty therapists are experts in facial and body care and have a strong knowledge of skin biology and cosmetic chemistry. ... Typical services include facials,
                  hair removal, massage, make-up, lash and brow treatments, manicures, pedicures, and other body treatments.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="accred">
            <h3 className="hny-title">Accreditation</h3>
            <p>GoshenEm Institute is accredited by Council for Technical Vocation Education and Training (COTVET), National Vocational Training Institute (NVTI). </p>
          </Col>
        </Row>

        <div className="course-duration">
          <Row>
            <Col md={12} className="py-5 text-center">
              <h3 className="sub-title">Courses and Duration</h3>
            </Col>
            <Col md={4}>
              <h4 className="hny-title">Hair</h4>
              <ul>
                <li>
                  shampooing <span>(6 weeks)</span>{" "}
                </li>
                <li>
                  Relaxing <span>(6 weeks)</span>
                </li>
                <li>
                  Colouring <span>(3 weeks)</span>
                </li>
                <li>
                  Hair Styling <span>(4 weeks)</span>
                </li>
                <li>
                  Weave-on <span>(4 weeks)</span>
                </li>
                <li>
                  Wig-caps <span>(3 weeks)</span>
                </li>
                <li>
                  hair cutting <span>(6 weeks)</span>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <h4 className="hny-title">Beauty</h4>
              <ul>
                <li>
                  nail extension <span>(1 month)</span>
                </li>
                <li>
                  waxing <span>(3 weeks)</span>
                </li>
                <li>
                  manicure <span>(4 weeks)</span>
                </li>
                <li>
                  pendicure <span>(4 weeks)</span>
                </li>
                <li>
                  eyelash extension <span>(2 weeks)</span>
                </li>
                <li>
                  facial <span>(6 weeks)</span>
                </li>
                <li>
                  massage <span>(4 weeks)</span>
                </li>
                <li>
                  make-up <span>(8 weeks)</span>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <h4 className="hny-title">Braiding</h4>
              <ul>
                <li>
                  cane roll <span>(4 weeks)</span>
                </li>
                <li>
                  single braids <span>(3 weeks)</span>
                </li>
                <li>
                  twist braids <span>(3 weeks)</span>
                </li>
                <li>
                  crocheting <span>(2 weeks)</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>

      <Footer />
    </div>
  );
}

export default Course;
