import React from 'react'
import './Service.css'
import Footer from '../Footer'



import { Container, Row, Col } from 'react-bootstrap'

import service_banner from '../../assest/img/banner8.jpg'

function Service() {
    return (
        <div className="service">
            <div className="service-header">
                <img src={service_banner} alt="" />
            </div>

            <Container className="py-5">
                <Row>
                    <Col className="py-5 h-service">
                        <h6 className="sub-title">Our Service</h6>
                        <h3 className="hny-title">What We Do</h3>
                        <p className="mt-3">We are approved by Government and our curriculum 
                        is designed not only to achieve the technical skills necessary in 
                        cosmetology &amp; beauty therapy but to make our students aware of the part they play in the service to others... </p>

                        <div className="service-list">
                            <div className="service-list-left">
                                <p><i className="fa fa-check"></i> facials</p>
                                <p><i className="fa fa-check"></i> body treatment</p>
                                <p><i className="fa fa-check"></i> waxing</p>
                                <p><i className="fa fa-check"></i> massage</p>
                                <p><i className="fa fa-check"></i> wave-on</p>
                                <p><i className="fa fa-check"></i> nail extension</p>
                                <p><i className="fa fa-check"></i> bridal make-up</p>
                            </div>
                            <div className="service-list-right">
                                <p><i className="fa fa-check"></i> braiding</p>
                                <p><i className="fa fa-check"></i> hands &amp; feet</p>
                                <p><i className="fa fa-check"></i> aroma therapy</p>
                                <p><i className="fa fa-check"></i> eye lashes</p>
                                <p><i className="fa fa-check"></i> chemical hair relaxing</p>
                                <p><i className="fa fa-check"></i> roller setting</p>
                                <p><i className="fa fa-check"></i> styling</p>
                                <p><i className="fa fa-check"></i> wigcaps</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            

            <Footer />
        </div>
    )
}

export default Service
